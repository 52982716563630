<template>
  <div class="dialog-add-account" v-loading="loading">
    <div class="dialog-add-account__title" v-html="$t('business_account_data.title')">
    </div>

    <div class="dialog-add-account__desc">
      {{ $t('business_account_data.description') }}
    </div>

    <form class="dialog-add-account__form">
      <div class="dialog-add-account__form-row is-options-row">
        <div class="dialog-add-account__form-col dialog-add-account__form-col--big with-options" :class="{'with-options' : isEditable}">
          <div v-if="showSwitcher" class="dialog-add-account__form-type">
            <label :class="{active: !form.is_legal}" class="form-control">
              <input type="radio" :value="false" v-model="form.is_legal">
              {{ $t('business_account_data.individual') }}
            </label>
            <label :class="{active: form.is_legal}" class="form-control">
              <input type="radio" :value="true" v-model="form.is_legal">
              {{ $t('business_account_data.ent_or_legal') }}
            </label>
          </div>

          <div
            v-if="role === 'manager' || role === 'finance' || role === 'admin'"
            class="dialog-add-account__show-settings"
            :class="{ 'dialog-add-account__show-settings--show': showSettings }"
            @click="showSettings = !showSettings"
          >
            <img src="@/assets/icons/icon-settings.svg" alt="settings" title="settings">
            <span>{{$t('business_account_data.settings')}}</span>
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5625 4.46875L6.5 8.53125L2.4375 4.46875" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>

      <transition name="fade">
        <div class="dialog-add-account__settings" v-if="showSettings">
          <div v-if="role === 'manager' || role === 'finance' || role === 'admin'" class="dialog-add-account__form-title">
            {{$t('business_account_data.role_manager')}}
          </div>
          <div v-if="role === 'manager' || role === 'finance' || role === 'admin'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div class="form-input" :class="[{'form-input--full' : form.tracker_manager_id}]">
                    <input v-model="form.tracker_manager_id" type="text">
                    <span class="form-input__label"> {{$t('business_account_data.input_id')}} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ID prefix  -->
          <div v-if="role === 'manager' || role === 'finance' || role === 'admin'" class="dialog-add-account__form-title">
            {{$t('business_account_data.id_prefix')}}
          </div>
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div class="form-input" :class="[{'form-input--full' : form.ba_prefix}]">
                    <input v-model="form.ba_prefix" type="text">
                    <span class="form-input__label"> {{$t('business_account_data.input_prefix')}} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Mytarget -->
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='RU'" class="dialog-add-account__form-title">
            {{$t('business_account_data.type_agent_mytarget')}}
          </div>
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='RU'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.mytarget !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-model="agency_account_type.mytarget"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        {{$t('business_account_data.gray')}}
                      </option>
                      <option value="white">
                        AM
                      </option>
                      <option value="profitads">
                        Profitads
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{$t('business_account_data.type__mytarget')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Yandex -->
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-title">
            {{$t('business_account_data.type_yandex_agent')}}
          </div>
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.yandex !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-if="locale==='RU'"
                      v-model="agency_account_type.yandex"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        AM
                      </option>
                      <option value="profitads">
                        Profitads
                      </option>
                    </select>
                    <select
                      v-if="locale==='USA'"
                      v-model="agency_account_type.yandex"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        DE Yandex
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{$t('business_account_data.type_yandex')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <!-- tiktok -->
           <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale === 'USA'" class="dialog-add-account__form-title">
            {{ $t('user_data.type_tiktok') }}
          </div>
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale === 'USA'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.tiktok !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-if="locale==='RU'"
                      v-model="agency_account_type.tiktok"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        AM
                      </option>
                      <option value="profitads">
                        Profitads
                      </option>
                    </select>
                    <select
                      v-if="locale==='USA'"
                      v-model="agency_account_type.tiktok"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        DE TikTok
                      </option>
                      <option value="am">
                        AM TikTok
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{ $t('user_data.choose_type_tiktok') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Vkontakte -->
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='RU'" class="dialog-add-account__form-title">
            {{$t('business_account_data.type_vk_agent')}}
          </div>
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='RU'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.vkontakte !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-model="agency_account_type.vkontakte"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        AM
                      </option>
                      <option value="profitads">
                        Profitads
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{$t('business_account_data.type_vk')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- VK ads -->
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-title">
            {{$t('business_account_data.type_vk_ad')}}
          </div>
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.vkads !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-if="locale==='RU'"
                      v-model="agency_account_type.vkads"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        AM
                      </option>
                      <option value="profitads">
                        Profitads
                      </option>
                    </select>
                    <select
                      v-if="locale==='USA'"
                      v-model="agency_account_type.vkads"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        DE VKAds
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{$t('business_account_data.choose_type_vk_ad')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Meta -->
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='USA'" class="dialog-add-account__form-title">
            {{$t('business_account_data.type_fb_agent')}}
          </div>
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='USA'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.facebook !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-model="agency_account_type.facebook"
                      required
                      :disabled="!isEditable"
                    >
                    <option value="default">
                        DE MetaUSD
                      </option>
                      <option value="digital_pln">
                        DE MetaPLN
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{$t('business_account_data.choose_type_fb_ad')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <!-- google -->
           <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='USA'" class="dialog-add-account__form-title">
            {{$t('user_data.type_google')}}
          </div>
          <div v-if="(role === 'manager' || role === 'finance'|| role === 'admin') && locale==='USA'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <div class="dialog-add-account__form-item">
                  <div
                    class="form-input"
                    :class="[
                    {'form-input--full' : agency_account_type.google !== 'not_receive'}
                  ]"
                  >
                    <select
                      v-model="agency_account_type.google"
                      required
                      :disabled="!isEditable"
                    >
                      <option value="default">
                        DE Google
                      </option>
                      <option value="easystart">
                        DE GoogleES
                      </option>
                    </select>
                    <span class="form-input__label">
                      {{$t('user_data.choose_type_google')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Course markup -->
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-title">
            {{$t('business_account_data.course_markup')}}
          </div>
          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div v-if="activeAccount" class="dialog-add-account__form-col dialog-add-account__form-col--big course-percent">
                <div v-for="(percent, currency) in form.course_percents" :key="currency" class="dialog-add-account__form-item">
                  <div class="form-input course-markup" :class="{ 'form-input--full': form.course_percents }">
                    <input v-model="form.course_percents[currency]" type="text">
                    <span class="form-input__label">{{ currency }}, %</span>
                  </div>
                </div>
              </div>
              <div v-else class="dialog-add-account__form-col dialog-add-account__form-col--big course-percent">
                <div class="dialog-add-account__form-item">
                  <div v-if="locale==='RU'" class="form-input course-markup" :class="[{'form-input--full' : ('USD' in form.course_percents && form.course_percents['USD'])}]">
                    <input v-model="form.course_percents.USD" type="text">
                    <span class="form-input__label"> USD, %</span>
                  </div>
                  <div v-if="locale==='USA'" class="form-input course-markup" :class="[{'form-input--full' : ('PLN' in form.course_percents && form.course_percents['PLN'])}]">
                    <input v-model="form.course_percents.PLN" type="text">
                    <span class="form-input__label"> PLN, %</span>
                  </div>
                </div>
                <div class="dialog-add-account__form-item">
                  <div class="form-input course-markup" :class="[{'form-input--full' : ('EUR' in form.course_percents && form.course_percents['EUR'])}]">
                    <input v-model="form.course_percents.EUR" type="text">
                    <span class="form-input__label"> EUR, %</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div v-if="locale==='RU'" class="dialog-add-account__form-col">
                <div class="dialog-add-account__form-item">
                  <div class="dialog-add-account__form-title">{{$t('business_account_data.vat_settings')}}</div>
                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.yandex"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.yandex_vat')"> 
                      </span>
                    </span>
                  </label>

                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.vkontakte"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.vk_vat')">
                      </span>
                    </span>
                  </label>

                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.vkads"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.vk_add_vat')">
                      </span>
                    </span>
                  </label>

                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.mytarget"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.mytarget_vat')">
                      </span>
                    </span>
                  </label>
                  <label v-if="locale ==='USA'" class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.facebook"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.facebook_vat')">   
                      </span>
                    </span>
                  </label>
                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.google"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.google_vat')">
                     
                      </span>
                    </span>
                  </label>
                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.telegram"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.telegram_vat')">
                      </span>
                    </span>
                  </label>
                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.promopages"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.promopages_vat')">
                      </span>
                    </span>
                  </label>
                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.avito"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.avito_vat')">
                      </span>
                    </span>
                  </label>
                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.vkmarket"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.vkmarket_vat')">
                      </span>
                    </span>
                  </label>
                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.metricapro"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.metricapro_vat')">
                      </span>
                    </span>
                  </label>
                  <label v-if="locale==='USA'" class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.tax_conditions.tiktok"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.tiktok_vat')">
                      </span>
                    </span>
                  </label>
                </div>
              </div>
              <!-- Visibility settings -->
              <div class="dialog-add-account__form-col">
                <div class="dialog-add-account__form-item">
                  <div class="dialog-add-account__form-title">{{ $t('business_account_data.visibility_settings') }}</div>
                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      v-model="form.visible_services.yandex"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_yandex')">
                      </span>
                    </span>
                  </label>

                  <label v-if="locale==='RU'" class="input__item-checkbox">
                    <input
                      type="checkbox"
                      v-model="form.visible_services.vkontakte"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_vk')">
                      </span>
                    </span>
                  </label>

                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      v-model="form.visible_services.vkads"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_vk_ad')">
                    
                      </span>
                    </span>
                  </label>
                  <label v-if="locale==='RU'" class="input__item-checkbox">
                    <input
                      type="checkbox"
                      v-model="form.visible_services.mytarget"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_mytarget')">

                      </span>
                    </span>
                  </label>
                  <label v-if="locale ==='USA'" class="input__item-checkbox">
                    <input
                      type="checkbox"
                      v-model="form.visible_services.facebook"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_fb')">
                      </span>
                    </span>
                  </label>
                  <label class="input__item-checkbox">
                    <input
                      type="checkbox"
                      v-model="form.visible_services.google"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title"  v-html="$t('business_account_data.show_google')">
                      </span>
                    </span>
                  </label>
                  <label v-if="locale==='RU'" class="input__item-checkbox">
                    <input
                      type="checkbox"
                      value="'all'"
                      v-model="form.visible_services.telegram"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_telegram')">
                     
                      </span>
                    </span>
                  </label>
                  <label v-if="locale==='USA'" class="input__item-checkbox">
                    <input
                      type="checkbox"
                      v-model="form.visible_services.tiktok"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_tiktok')">
                      </span>
                    </span>
                  </label>
                  <label v-if="locale==='RU'" class="input__item-checkbox">
                    <input
                      type="checkbox"
                      v-model="form.visible_services.promopages"
                    >
                    <span class="input__item-checkbox__check">
                      <img src="@/assets/icons/icon-done.svg">
                    </span>
                    <span class="input__item-checkbox__info">
                      <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_promopages')">
                      </span>
                    </span>
                  </label>
                  <label v-if="locale!=='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.avito"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_avito')">
                        </span>
                      </span>
                    </label>
                    <label v-if="locale!=='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.vkmarket"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_vkmarket')">
                        </span>
                      </span>
                    </label>
                    <label v-if="locale!=='USA'" class="input__item-checkbox">
                      <input
                        type="checkbox"
                        v-model="form.visible_services.metricapro"
                      >
                      <span class="input__item-checkbox__check">
                        <img src="@/assets/icons/icon-done.svg">
                      </span>
                      <span class="input__item-checkbox__info">
                        <span class="input__item-checkbox__title" v-html="$t('business_account_data.show_metricapro')">
                        </span>
                      </span>
                    </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="role === 'manager' || role === 'finance'|| role === 'admin'" class="dialog-add-account__form-title">
            {{$t('business_account_data.automatization')}}
          </div>
          <div v-if="role === 'manager' || role === 'finance' || role === 'admin'" class="dialog-add-account__form-block">
            <div class="dialog-add-account__form-row">
              <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
                <!-- label 1C -->
                <label v-if="locale ==='RU'" class="input__item-checkbox">
                  <input
                    type="checkbox"
                    value="'all'"
                    v-model="form.auto_acts"
                  >
                  <span class="input__item-checkbox__check">
                    <img src="@/assets/icons/icon-done.svg">
                  </span>
                  <span class="input__item-checkbox__info">
                    <span class="input__item-checkbox__title" v-html="$t('business_account_data.auto1c')"> 
                    </span>
                  </span>
                </label>
                <!-- AutocashBack -->
                <label class="input__item-checkbox">
                  <input
                    type="checkbox"
                    value="'all'"
                    v-model="form.auto_cashback"
                  >
                  <span class="input__item-checkbox__check">
                    <img src="@/assets/icons/icon-done.svg">
                  </span>
                  <span class="input__item-checkbox__info">
                    <span class="input__item-checkbox__title" v-html="$t('business_account_data.auto_cashback')"> 
                    </span>
                  </span>
                </label>
                 <label class="input__item-checkbox">
                  <input
                    type="checkbox"
                    value="'all'"
                    v-model="form.access_api"
                  >
                  <span class="input__item-checkbox__check">
                    <img src="@/assets/icons/icon-done.svg">
                  </span>
                  <span class="input__item-checkbox__info">
                    <span class="input__item-checkbox__title" v-html="$t('business_account_data.access_to_api')"> 
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div v-if="locale==='RU'" class="dialog-add-account__form-title">
        {{ $t('business_account_data.company') }}
      </div>

      <div class="dialog-add-account__form-block">
        <div class="dialog-add-account__form-row">
          <div v-if="locale==='RU'" class="dialog-add-account__form-col">
            <div class="dialog-add-account__form-item">
              <div class="form-input" :class="[{'form-input--full' : form.inn},{'form-input--error' : errors.inn || (authErrors && authErrors.inn)}]">
                <input :disabled="isEditable && role === 'user'" v-model="form.inn" type="text" required>
                <span class="form-input__label"> {{ $t('business_account_data.inn') }} </span>
                <div v-if="errors.inn" class="form-input__error" v-text="errors.inn" />
                <div v-if="authErrors && authErrors.inn" class="form-input__error">
                  <p v-for="(err, index) in authErrors.inn" :key="index">
                    {{ err }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div v-if="locale==='RU'" class="dialog-add-account__form-col">
            <ButtonElement
              :text="$t('button_props.check_tin')"
              :disabled="isEditable && role === 'user'"
              @click-button="checkInn"
            />
          </div>

          <template v-if="isCorrectInn  || locale === 'USA'">
            <div v-if="locale === 'USA'" class="dialog-add-account__form-col dialog-add-account__form-col--big">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.inn},{'form-input--error' : errors.inn || (authErrors && authErrors.inn)}]">
                  <input v-model="form.inn" type="text" required :disabled="locale==='RU'">
                  <span class="form-input__label"> {{ $t('business_account_data.registration_number') }}</span>
                  <div v-if="errors.inn" class="form-input__error" v-text="errors.inn" />
                  <div v-if="authErrors && authErrors.inn" class="form-input__error">
                    <p v-for="(err, index) in authErrors.inn" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.legal_name},{'form-input--error' : errors.legal_name || (authErrors && authErrors.legal_name)}]">
                  <input v-model="form.legal_name" type="text" required :disabled="locale==='RU'">
                  <span class="form-input__label"> {{ $t('business_account_data.entity_name') }}</span>
                  <div v-if="errors.legal_name" class="form-input__error" v-text="errors.legal_name" />
                  <div v-if="authErrors && authErrors.legal_name" class="form-input__error">
                    <p v-for="(err, index) in authErrors.legal_name" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.legal_address},{'form-input--error' : errors.legal_address || (authErrors && authErrors.legal_address)}]">
                  <input v-model="form.legal_address" type="text" required :disabled="isDisabledLegalAddress && locale==='RU'">
                  <span class="form-input__label"> {{ $t('business_account_data.legal_address') }}</span>
                  <div v-if="errors.legal_address" class="form-input__error" v-text="errors.legal_address" />
                  <div v-if="authErrors && authErrors.legal_address" class="form-input__error">
                    <p v-for="(err, index) in authErrors.legal_address" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="locale==='RU'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.legal_receiving_physical_address},{'form-input--error' : errors.legal_receiving_physical_address || (authErrors && authErrors.legal_receiving_physical_address)}]">
                  <input
                      v-model="form.legal_receiving_physical_address"
                      :disabled="inputAddressDisabled"
                      type="text"
                      required
                  >
                  <span class="form-input__label">{{ $t('business_account_data.post_address') }}</span>
                  <div v-if="errors.legal_receiving_physical_address" class="form-input__error" v-text="errors.legal_receiving_physical_address" />
                  <div v-if="authErrors && authErrors.legal_receiving_physical_address" class="form-input__error">
                    <p v-for="(err, index) in authErrors.legal_receiving_physical_address" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="locale==='RU'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="dialog-add-account__form-correspond">
                  <label class="input__item-checkbox">
                    <input
                        type="checkbox"
                        value="'all'"
                        v-model="form.toggleAddress"
                        @change="copyAddress"
                    >
                    <span class="input__item-checkbox__check">
                  <img src="@/assets/icons/icon-done.svg">
                </span>
                    <span class="input__item-checkbox__info">
                  <span class="input__item-checkbox__title">
                    {{ $t('business_account_data.corresponding_legal') }}
                  </span>
                </span>
                  </label>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <template v-if="isCorrectInn || locale ==='USA'">
        <div v-if="locale ==='USA'" class="dialog-add-account__form-title">
          <!-- Authorized representative -->
          {{ $t('business_account_data.representative') }} 
        </div>

        <div v-if="locale ==='USA'" class="dialog-add-account__form-block">
          <div class="dialog-add-account__form-row">
            <div class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.contract_signature_firstname},{'form-input--error' : errors.contract_signature_firstname || (authErrors && authErrors.contract_signature_firstname)}]">
                  <input v-model="form.contract_signature_firstname" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.first_name') }}</span>
                  <div v-if="errors.contract_signature_firstname" class="form-input__error" v-text="errors.contract_signature_firstname" />
                  <div v-if="authErrors && authErrors.contract_signature_firstname" class="form-input__error">
                    <p v-for="(err, index) in authErrors.contract_signature_firstname" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.contract_signature_lastname},{'form-input--error' : errors.contract_signature_lastname || (authErrors && authErrors.contract_signature_lastname)}]">
                  <input v-model="form.contract_signature_lastname" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.last_name') }}</span>
                  <div v-if="errors.contract_signature_lastname" class="form-input__error" v-text="errors.contract_signature_lastname" />
                  <div v-if="authErrors && authErrors.contract_signature_lastname" class="form-input__error">
                    <p v-for="(err, index) in authErrors.contract_signature_lastname" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="locale==='USA'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.contract_signature_position},{'form-input--error' : errors.contract_signature_position || (authErrors && authErrors.contract_signature_position)}]">
                  <input v-model="form.contract_signature_position" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.position') }}</span>
                  <div v-if="errors.contract_signature_position" class="form-input__error" v-text="errors.contract_signature_position" />
                  <div v-if="authErrors && authErrors.contract_signature_position" class="form-input__error">
                    <p v-for="(err, index) in authErrors.contract_signature_position" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.contract_signature_document},{'form-input--error' : errors.contract_signature_document || (authErrors && authErrors.contract_signature_document)}]">
                  <input v-model="form.contract_signature_document" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.based_position') }}</span>
                  <div v-if="errors.contract_signature_document" class="form-input__error" v-text="errors.contract_signature_document" />
                  <div v-if="authErrors && authErrors.contract_signature_document" class="form-input__error">
                    <p v-for="(err, index) in authErrors.contract_signature_document" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
        <!-- The contact person -->
        <div class="dialog-add-account__form-title">
          {{ $t('business_account_data.contact_name') }}
        </div>

        <div class="dialog-add-account__form-block">
          <div class="dialog-add-account__form-row">
            <div class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.legal_contact_name},{'form-input--error' : errors.legal_contact_name || (authErrors && authErrors.legal_contact_name)}]">
                  <input v-model="form.legal_contact_name" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.name') }}</span>
                  <div v-if="errors.legal_contact_name" class="form-input__error" v-text="errors.legal_contact_name" />
                  <div v-if="authErrors && authErrors.legal_contact_name" class="form-input__error">
                    <p v-for="(err, index) in authErrors.legal_contact_name" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.phone},{'form-input--error' : errors.phone || (authErrors && authErrors.phone)}]">
                  <input v-if="locale==='RU'" v-model="form.phone" type="tel" v-mask="'+7(###)###-##-##'" required>
                  <input v-if="locale==='USA'" v-model="form.phone" type="tel" v-mask="'+############'" required>
                  <span class="form-input__label">{{ $t('business_account_data.phone') }}</span>
                  <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
                  <div v-if="authErrors && authErrors.phone" class="form-input__error">
                    <p v-for="(err, index) in authErrors.phone" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.email},{'form-input--error' : errors.email || (authErrors && authErrors.email)}]">
                  <input v-model="form.email" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.email') }}</span>
                  <div v-if="errors.email" class="form-input__error" v-text="errors.email" />
                  <div v-if="authErrors && authErrors.email" class="form-input__error">
                    <p v-for="(err, index) in authErrors.email" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div v-if="locale==='RU'" class="dialog-add-account__form-title">
          {{ $t('business_account_data.closing_doc') }}
        </div>

        <div v-if="locale==='RU'" class="dialog-add-account__form-block">
          <div class="dialog-add-account__form-row">
            <div class="dialog-add-account__form-col dialog-add-account__form-col--big">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.legal_receiving_docs_method !== 'not_receive'}, {'form-input--error' : errors.legal_receiving_docs_method}]">
                  <select v-model="form.legal_receiving_docs_method" required>
                    <option disabled> {{ $t('business_account_data.choose_op') }}</option>
                    <option value="in_office">{{ $t('business_account_data.edo') }}</option>
                    <option value="by_email">{{ $t('business_account_data.byemail') }}</option>
                  </select>

                  <span class="form-input__label">
                  {{ form.legal_receiving_docs_method !== 'not_receive' ? $t('business_account_data.get_orig') : $t('business_account_data.vay_to_get_orig') }}
                </span>
                  <div v-if="errors.legal_receiving_docs_method" class="form-input__error" v-text="errors.legal_receiving_docs_method" />
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col" v-if="form.legal_receiving_docs_method === 'by_email'">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.legal_receiving_mail_index},{'form-input--error' : errors.legal_receiving_mail_index || (authErrors && authErrors.legal_receiving_mail_index)}]">
                  <input v-model="form.legal_receiving_mail_index" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.index') }}</span>
                  <div v-if="errors.legal_receiving_mail_index" class="form-input__error" v-text="errors.legal_receiving_mail_index" />
                  <div v-if="authErrors && authErrors.legal_receiving_mail_index" class="form-input__error">
                    <p v-for="(err, index) in authErrors.legal_receiving_mail_index" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col" v-if="form.legal_receiving_docs_method === 'by_email'">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.legal_receiving_mail_recipient},{'form-input--error' : errors.legal_receiving_mail_recipient}]">
                  <input v-model="form.legal_receiving_mail_recipient" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.receiver') }} </span>
                  <div v-if="errors.legal_receiving_mail_recipient" class="form-input__error" v-text="errors.legal_receiving_mail_recipient" />
                </div>
              </div>
            </div>

            <div class="dialog-add-account__form-col dialog-add-account__form-col--big" v-if="form.legal_receiving_docs_method === 'by_email'">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.legal_receiving_mail_address},{'form-input--error' : errors.legal_receiving_mail_address || (authErrors && authErrors.legal_receiving_mail_address)}]">
                  <input v-model="form.legal_receiving_mail_address" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.address') }}</span>
                  <div v-if="errors.legal_receiving_mail_address" class="form-input__error" v-text="errors.legal_receiving_mail_address" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="dialog-add-account__form-title">
          <!-- Bank -->
          {{ $t('business_account_data.bank') }}
        </div>
        <!-- USD EURO switcher -->
        <div v-if="locale === 'USA'" class="dialog-add-account__form-type dialog-add-account__form-type--radio">
            <label :class="{active: currency === 'USD', error: isError}" class="form-control">
              <input type="radio" :value="'USD'" v-model="currency">
              {{ $t('business_account_data.usd') }}
            </label>
            <label :class="{active: currency === 'EUR', error: localBankError}" class="form-control">
              <input type="radio" :value="'EUR'" v-model="currency">
              {{ $t('business_account_data.euro') }}
            </label>
        </div>

        <div class="dialog-add-account__form-block">
          <div class="dialog-add-account__form-row">
            <div v-if="currency === 'USD'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.bank_name}, {'form-input--error' : (authErrors && authErrors.bank_name)}]">
                  <input v-model="form.bank_name" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.bank_name') }}</span>
                  <!-- <div v-if="errors.bank_name" class="form-input__error" v-text="errors.bank_name" /> -->
                  <div v-if="authErrors && authErrors.bank_name" class="form-input__error">
                    <p v-for="(err, index) in authErrors.bank_name" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- EUR input bank_name -->
            <div v-if="currency === 'EUR'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : additional_banks.EUR.bank_name},
                {'form-input--error' : (authErrors && authErrors.bank_name)}]">
                  <input v-model="additional_banks.EUR.bank_name" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.bank_name') }} EUR</span>
                  <div v-if="authErrors && authErrors.bank_name" class="form-input__error">
                    <p v-for="(err, index) in authErrors.bank_name" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="locale==='RU'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.bic},{'form-input--error' : errors.bic || (authErrors && authErrors.bic)}]">
                  <input v-model="form.bic" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.bic') }}</span>
                  <div v-if="errors.bic" class="form-input__error" v-text="errors.bic" />
                  <div v-if="authErrors && authErrors.bic" class="form-input__error">
                    <p v-for="(err, index) in authErrors.bic" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="locale==='RU'"  class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.iban},{'form-input--error' : errors.iban || (authErrors && authErrors.iban)}]">
                  <input v-model="form.iban" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.account') }}</span>
                  <div v-if="errors.iban" class="form-input__error" v-text="errors.iban" />
                  <div v-if="authErrors && authErrors.iban" class="form-input__error">
                    <p v-for="(err, index) in authErrors.iban" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="currency === 'USD'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.swift_code},{'form-input--error' :  (authErrors && authErrors.swift_code)}]">
                  <input v-model="form.swift_code" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.correspondent_account') }}</span>
                  <!-- <div v-if="errors.swift_code" class="form-input__error" v-text="errors.swift_code" /> -->
                  <div v-if="authErrors && authErrors.swift_code" class="form-input__error">
                    <p v-for="(err, index) in authErrors.swift_code" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- EUR input  swift_code-->
            <div v-if="currency === 'EUR'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <!-- <div class="form-input" :class="[{'form-input--full' : additional_banks.EUR.swift_code},{'form-input--error' : errors.swift_code || (authErrors && authErrors.swift_code)}]"> -->
                <div class="form-input" :class="[{'form-input--full' : additional_banks.EUR.swift_code},{'form-input--error' : (authErrors && authErrors.swift_code)}]">
                  <input v-model="additional_banks.EUR.swift_code" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.correspondent_account') }} EUR</span>
                  <!-- <div v-if="errors.swift_code" class="form-input__error" v-text="errors.swift_code" /> -->
                  <div v-if="authErrors && authErrors.swift_code" class="form-input__error">
                    <p v-for="(err, index) in authErrors.swift_code" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="locale==='USA' && currency === 'USD'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.bic},{'form-input--error' :  (authErrors && authErrors.bic)}]">
                  <input v-model="form.bic" type="text">
                  <span class="form-input__label">{{ $t('business_account_data.routing_number') }}</span>
                  <!-- <div v-if="errors.bic" class="form-input__error" v-text="errors.bic" /> -->
                  <div v-if="authErrors && authErrors.bic" class="form-input__error">
                    <p v-for="(err, index) in authErrors.bic" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- EUR input  bic-->
            <div v-if="currency === 'EUR'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : additional_banks.EUR.bic},{'form-input--error' : (authErrors && authErrors.bic)}]">
                  <input v-model="additional_banks.EUR.bic" type="text">
                  <span class="form-input__label">{{ $t('business_account_data.routing_number') }} EUR</span>
                  <!-- <div v-if="errors.bic" class="form-input__error" v-text="errors.bic" /> -->
                  <div v-if="authErrors && authErrors.bic" class="form-input__error">
                    <p v-for="(err, index) in authErrors.bic" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
    
            <div v-if="locale==='USA' && currency === 'USD'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.iban},{'form-input--error' : errors.iban || (authErrors && authErrors.iban)}]">
                  <input v-model="form.iban" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.account_number') }}</span>
                  <div v-if="errors.iban" class="form-input__error" v-text="errors.iban" />
                  <div v-if="authErrors && authErrors.iban" class="form-input__error">
                    <p v-for="(err, index) in authErrors.iban" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- EUR input iban -->
            <div v-if="locale==='USA' && currency === 'EUR'" class="dialog-add-account__form-col">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : additional_banks.EUR.iban},{'form-input--error' : errors.iban || (authErrors && authErrors.iban)}]">
                  <input v-model="additional_banks.EUR.iban" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.account_number') }} EUR</span>
                  <div v-if="errors.iban" class="form-input__error" v-text="errors.iban" />
                  <div v-if="authErrors && authErrors.iban" class="form-input__error">
                    <p v-for="(err, index) in authErrors.iban" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="currency === 'USD'" class="dialog-add-account__form-col dialog-add-account__form-col--big">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : form.bank_address},{'form-input--error' :  (authErrors && authErrors.bank_address)}]">
                  <input v-model="form.bank_address" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.bank_address') }}</span>
                  <!-- <div v-if="errors.bank_address" class="form-input__error" v-text="errors.bank_address" /> -->
                  <div v-if="authErrors && authErrors.bank_address" class="form-input__error">
                    <p v-for="(err, index) in authErrors.bank_address" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- EUR bank_address -->
            <div v-if="currency === 'EUR'" class="dialog-add-account__form-col dialog-add-account__form-col--big">
              <div class="dialog-add-account__form-item">
                <div class="form-input" :class="[{'form-input--full' : additional_banks.EUR.bank_address},{'form-input--error' : (authErrors && authErrors.bank_address)}]">
                  <input v-model="additional_banks.EUR.bank_address" type="text" required>
                  <span class="form-input__label">{{ $t('business_account_data.bank_address') }} EUR</span>
                  <!-- <div v-if="errors.bank_address" class="form-input__error" v-text="errors.bank_address" /> -->
                  <div v-if="authErrors && authErrors.bank_address" class="form-input__error">
                    <p v-for="(err, index) in authErrors.bank_address" :key="index">
                      {{ err }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isEditable && showSwitcher" class="dialog-add-account__form-agree">
          <label v-if="locale!=='USA'" class="input__item-checkbox">
            <input
                type="checkbox"
                value="'all'"
                required
                v-model="form.offer_agreement_accepted"
            >
            <span class="input__item-checkbox__check">
            <img src="@/assets/icons/icon-done.svg">
          </span>
            <span class="input__item-checkbox__info">
            <span class="input__item-checkbox__title">
              {{ $t('business_account_data.agree_with') }}
              <a href="https://profitads.ru/dogovor-oferty/" target="_blank">{{ $t('business_account_data.offer_contract') }}</a>
            </span>
          </span>
          </label>
          <div v-if="errors.offer_agreement_accepted && locale!=='USA'" class="form-input__error" v-text="errors.offer_agreement_accepted" />
        </div>
        <div v-else-if="showSwitcher" class="dialog-add-account__form-agree">
          <label v-if="locale!=='USA'" class="input__item-checkbox">
            <input
                type="checkbox"
                value="'all'" 
                required
                v-model="form.offer_agreement_accepted"
            >
            <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('business_account_data.agree_with') }}
                <a href="https://profitads.ru/dogovor-oferty/" target="_blank">{{ $t('business_account_data.offer_contract') }}</a>
              </span>
            </span>
          </label>
          <div v-if="errors.offer_agreement_accepted && locale!=='USA'" class="form-input__error" v-text="errors.offer_agreement_accepted" />
        </div>
        <div v-if="locale==='USA' && errors.bank_error" class="form-input__error" v-text="errors.bank_error" />
        <div class="dialog-add-account__form-buttons">
          <ButtonElement
              :text="$t('button_props.save')"
              @click-button="saveData"
          />
          <ButtonElement
              :text="$t('button_props.cancel')"
              view="empty"
              @click-button="closeDialog"
          />
        </div>
      </template>
    </form>
  </div>
</template>

<script>
import formValidate from '../../mixin/formValidate'
import ButtonElement from '../../components/elements/ButtonElement'

export default {
  name: 'BusinessAccountData',
  components: {
    ButtonElement
  },
  mixins: [formValidate],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      loading: false,
      isCorrectInn: false,
      inputAddressDisabled: false,
      isDisabledLegalAddress: false,
      form: {
        partner: '',
        is_legal: true,
        email: '',
        phone: '',
        inn: '',
        bic: '',
        iban: '',
        swift_code: '',
        bank_name: '',
        bank_address: '',
        legal_name: '',
        legal_address: '',
        legal_country: 'Russia',
        legal_contact_name: '',
        legal_receiving_docs_method: 'not_receive',
        legal_receiving_mail_index: '',
        legal_receiving_mail_address: '',
        legal_receiving_mail_recipient: '',
        position:'',
        based_position:'',
        toggleAddress: '',
        legal_receiving_physical_address: '',
        course_percents: {EUR: 5, USD: 5, PLN: 5},
        tax_conditions: {
          mytarget: process.env.VUE_APP_LOCALE==='RU'? true : false,
          vkontakte: process.env.VUE_APP_LOCALE==='RU'? false : false,
          facebook: process.env.VUE_APP_LOCALE==='RU'? false : false,
          yandex: process.env.VUE_APP_LOCALE==='RU'? false : false,
          vkads: process.env.VUE_APP_LOCALE==='RU'? true : false,
          telegram: process.env.VUE_APP_LOCALE==='RU'? true : false,
          tiktok: process.env.VUE_APP_LOCALE==='RU'? false: false,
          google: process.env.VUE_APP_LOCALE==='RU'? true: false,
          promopages: process.env.VUE_APP_LOCALE==='RU'? true : false,
          avito: process.env.VUE_APP_LOCALE==='RU'? false : false,
          vkmarket: process.env.VUE_APP_LOCALE==='RU'? false : false,
          metricapro: process.env.VUE_APP_LOCALE==='RU'? false : false
        },
        visible_services: {
          mytarget: process.env.VUE_APP_LOCALE==='RU'? true: false,
          vkontakte: process.env.VUE_APP_LOCALE==='RU'? true: false,
          facebook: process.env.VUE_APP_LOCALE==='RU'? false: true,
          yandex: process.env.VUE_APP_LOCALE==='RU'? true: false,
          google: process.env.VUE_APP_LOCALE==='RU'? true: true,
          vkads: process.env.VUE_APP_LOCALE==='RU'? true: false,
          telegram: process.env.VUE_APP_LOCALE==='RU'? true: false,
          tiktok: process.env.VUE_APP_LOCALE==='RU'? false: true,
          promopages: process.env.VUE_APP_LOCALE==='RU'? true : false,
          avito: process.env.VUE_APP_LOCALE==='RU'? true : true,
          vkmarket: process.env.VUE_APP_LOCALE==='RU'? true : true,
          metricapro: process.env.VUE_APP_LOCALE==='RU'? true : true
        },
        offer_agreement_accepted: '',
        tracker_manager_id: '',
        course_percent: 0,
        ba_prefix:'',
        first_name: '',
        last_name:'',
        contract_signature_firstname:'',
        contract_signature_lastname:'',
        contract_signature_position:'',
        contract_signature_document:'',
        auto_acts: true,
        auto_cashback: true,
        access_api: false,
      },
      errors: {
        is_legal: false,
        legal_name: '',
        inn: '',
        legal_address: '',
        legal_contact_name: '',
        bank_name: '',
        email: '',
        phone: '',
        agree_terms_contract: '',
        legal_receiving_mail_index: '',
        legal_receiving_mail_recipient: '',
        legal_receiving_mail_address: '',
        legal_receiving_physical_address: '',
        bic: '',
        swift_code: '',
        bank_address: '',
        legal_receiving_docs_method: '',
        offer_agreement_accepted: '',
        position:'',
        based_position:'',
        contract_signature_firstname:'',
        contract_signature_lastname:'',
        contract_signature_position:'',
        contract_signature_document:'',
        bank_error: ''
      },
      showSettings: false,
      currency:"USD",
      additional_banks: {
        EUR: {
            bank_name : "",
            bank_address : "",
            swift_code : "",
            bic : "",
            iban : "",
            currency:"EUR"
          },
      },
      additional_banks_errors: {
        EUR: {
            bank_name : "",
            bank_address : "",
            swift_code : "",
            bic : ""
          },
      }
    }
  },
  computed: {
    showSwitcher() {
      if(this.$route && this.$route.name !== 'ClientsInfo' && this.isOpenUL) {
        return false
      }  
      else if(this.$route && this.$route.name === 'ClientsInfo') {
        return true
      } else if (this.$route && this.$route.name !== 'ClientsInfo' && (this.user.role !=='admin' || this.user.role !=='finance')) {
        return true
      } else {
        return false
      }
    },
    isOpenUL() {
      return this.$store.state.Dialog.isOpenUL
    },
    accountAutocomplete() {
      if(this.$route && this.$route.name === 'ClientsInfo') {
        return false
      }
      return true
    },
    isEditable() {
      return this.$store.state.Dialog.isEditable
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    user() {
      return this.$store.state.Auth.user
    },
    role() {
      return this.$store.state.Auth.role
    },
    innField() {
      return this.form.inn
    },
    isLegal() {
      return this.form.is_legal
    },
    localBankError() {
      let error = false
      for (const key in this.additional_banks_errors.EUR) {
        if (this.additional_banks_errors.EUR[key]) error = true
      }
      return error
    },
    euroError() {
      return this.additional_banks.EUR
    },
    agency_account_type() {
      return {
        mytarget: "profitads",
        yandex: "profitads",
        vkontakte: "profitads",
        vkads: this.locale === "RU" ? "profitads" : "default",
        google: this.locale === "RU" ? "profitads" : "default",
        tiktok: "default",
        facebook: "default"
      }
    }
  },
  watch: {
    innField(v1, v2) {
      if (v1 && v2) this.isCorrectInn = false
    },
    isLegal(val) {
      if (!val) {
        this.$store.dispatch('Dialog/setData', { label: 'activeDialog', data: 'UserData' })
      }
    },
     euroError: {
      deep: true,
      handler () {
        this.$store.commit('Auth/setData', { label: 'errors', data: null })
        for (const key in this.additional_banks_errors.EUR) {
          this.additional_banks_errors.EUR[key] = null
        }
      }
    }
  },
  mounted() {
    this.agency_account_type.yandex = this.locale === "RU" ? "profitads" : "default"

    if (this.isEditable && this.accountAutocomplete) {
      if(this.activeAccount) {
        for (let key in this.form) {
          this.form[key] = this.activeAccount[key]
        }
      }

      if(this.activeAccount && this.activeAccount.additional_banks.length ) {
        for (const key in this.additional_banks) {
            const currency = this.additional_banks[key].currency;
            const matchingBank = this.activeAccount.additional_banks.find(bank => bank.currency === currency);
            if (matchingBank) {
                this.additional_banks[key] = { ...this.additional_banks[key], ...matchingBank };
            }
        }
      }

      for (let key in this.agency_account_type) {
        if (this.activeAccount && 
          this.activeAccount.agency_account_type && 
          this.activeAccount.agency_account_type[key]
        ) {
          this.agency_account_type[key] = this.activeAccount.agency_account_type[key]
        }
      }
      this.form.offer_agreement_accepted = true
      this.isCorrectInn = true
    }
  },
  methods: {
    clearForm() {
      this.form.is_legal = true
      this.form.legal_address = ''
      this.inn = '',
      this.bic = '',
      this.iban = '',
      this.swift_code = '',
      this.bank_name = '',
      this.bank_address = '',
      this.legal_name = '',
      this.legal_address = '',
      this.legal_contact_name = '',
      this.legal_contact_last_name = '',
      this.legal_receiving_docs_method = 'not_receive',
      this.legal_receiving_mail_index = '',
      this.legal_receiving_mail_address = '',
      this.legal_receiving_mail_recipient = '',
      this.legal_receiving_physical_address = '',
      this.form.email = '',
      this.form.phone = '',
      this.position = '',
      this.based_position = '',
      this.form.offer_agreement_accepted = false,
      this.contract_signature_firstname = '',
      this.contract_signature_lastname = '',
      this.contract_signature_position = '',
      this.contract_signature_document = '',
      this. additional_banks = {
        EUR: {
            bank_name : "",
            bank_address : "",
            swift_code : "",
            bic : "",
            iban : "",
            currency:"EUR"
          },
      }
    },
    saveData() {
      // if (this.isEditable) {
      //   this.editAccount()
      // } else {
      //   this.addNewAccount()
      // }
      if(this.isEditable && !this.accountAutocomplete) {
        this.addNewAccount()
      } else if (this.isEditable && this.accountAutocomplete) {
        this.editAccount()
      } else {
        this.addNewAccount()
      }
    },
    editAccount() {
      this.validateForm()
      if (this.isError || this.localBankError) {
        return false
      }
      this.loading = true
      const dataForm = {}
      for (let key in this.form) {
        if(this.form[key]) {
          dataForm[key] = this.form[key]
        }
        if((key === 'bank_name'|| key === 'bank_address'|| key === 'bic'|| key === 'swift_code' || key === 'iban') 
          && !dataForm[key] && this.locale === 'USA') {
            dataForm[key] = ''
          }
      }
      // dataForm.tracker_manager_id = this.form.tracker_manager_id ? this.form.tracker_manager_id : '8000000000000009'
      dataForm.tracker_manager_id = this.locale === 'USA' ? (this.form.tracker_manager_id ? this.form.tracker_manager_id : '8000000000000009') : (this.form.tracker_manager_id ? this.form.tracker_manager_id : '1130000059812774');
      dataForm.is_legal = this.form.is_legal
      dataForm.agency_account_type = {...this.agency_account_type}
      dataForm.ba_prefix = this.form.ba_prefix
      dataForm.auto_acts = this.form.auto_acts
      dataForm.auto_cashback = this.form.auto_cashback
      dataForm.access_api = this.form.access_api
      dataForm.additional_banks = []
      dataForm.course_percents = this.removeEmpty(this.form.course_percents)
      
      // if(this.locale ==='USA' && this.isObjectBankValid(this.additional_banks)) {  
      //   dataForm.additional_banks = []   
      //     for (const key of Object.keys(this.additional_banks)) {
      //         dataForm.additional_banks.push(this.additional_banks[key]);
      //     }
      // }

      if(this.locale ==='USA') {     
          for (const key of Object.keys(this.additional_banks)) {
              dataForm.additional_banks.push(this.additional_banks[key]);
          }
      }

      const data = {
        id: this.activeAccount.id,
        data: dataForm
      }
      this.$store.dispatch('Auth/editBusinessAccount', data)
          .then(() => {
            this.loading = false
            this.dialogVisible = false
            this.$notify({
              message: this.$t('business_account_data.changes_saved'),
              type: 'success'
            });
            this.clearForm()
            this.closeDialog()
          })
          .catch(() => {
            this.loading = false
            this.$notify({
              message: this.$t('business_account_data.error'),
              type: 'error'
            });
          })
    },
    addNewAccount() {
      this.validateForm()
      // this.validateBank()
      if (this.isError || this.localBankError) {
        return false
      }
      this.loading = true
      const data = {}
      for (let key in this.form) {
        if(this.form[key]) {
          data[key] = this.form[key]
        }
      }
      // data.tracker_manager_id = this.form.tracker_manager_id ? this.form.tracker_manager_id : '8000000000000009'
      data.tracker_manager_id = this.locale === 'USA' ? (this.form.tracker_manager_id ? this.form.tracker_manager_id : '8000000000000009') : (this.form.tracker_manager_id ? this.form.tracker_manager_id : '1130000063850009');
      data.agency_account_type = {...this.agency_account_type}
      data.is_legal = this.form.is_legal
      data.ba_prefix = this.form.ba_prefix
      data.auto_acts = this.form.auto_acts
      data.auto_cashback = this.form.auto_cashback
      data.access_api = this.form.access_api
      data.additional_banks = []
      data.course_percents = this.removeEmpty(this.form.course_percents)
      if(this.locale ==='USA') {
        // data.course_percent = this.form.course_percent
        if(this.currency !=='USD') {
          // data.additional_banks = data.additional_banks.push(this.additional_banks[this.currency])
          if(this.currency !=='USD') {
          for (const key of Object.keys(this.additional_banks)) {
              data.additional_banks.push(this.additional_banks[key]);
          }
        }
        }

      }
      if (this.role === 'admin' || this.role === 'manager' || this.role === 'finance') {
        data.user_id = this.$store.state.Dialog.userIdForAccount
        const token = localStorage.getItem('token')
        this.$store.dispatch('Auth/createBusinessAccountLikeManager', {data, token})
          .then(() => {
            this.loading = false
            this.dialogVisible = false
            this.$notify({
              message: this.$t('business_account_data.accaunt_created'),
              type: 'success'
            });
            this.clearForm()
            this.closeDialog()
          })
          .catch(() => {
            this.loading = false
            this.$notify({
              message: this.$t('business_account_data.accaunt_error'),
              type: 'error'
            });
          })
      } else {
        this.$store.dispatch('Auth/createBusinessAccount', data)
          .then(() => {
            this.loading = false
            this.dialogVisible = false
            this.$notify({
              message: this.$t('business_account_data.accaunt_created'),
              type: 'success'
            });
            this.clearForm()
            this.closeDialog()
          })
          .catch(() => {
            this.loading = false
            this.$notify({
              message: this.$t('business_account_data.error'),
              type: 'error'
            });
          })
      }
    },
    validateForm () {
      if (!this.form.email) {
        this.errors.email = this.$t('business_account_data.input_email')
      } else {
        this.validateEmail()
      }
      if (!this.form.phone) {
        this.errors.phone = this.$t('business_account_data.input_phone')
      } else {
        // const regPhone = /^(\+7\(?\d{3}\)?\d{3}-?\d{2}-?\d{2})$/
        // const regPhone = this.locale==='RU'? (/^(\+7\(?\d{3}\)?\d{3}-?\d{2}-?\d{2})$/) : (/^(\+1)?[ -]?\(?([0-9]{3})\)?[ -]?([0-9]{3})[ -]?([0-9]{4})$/)
        const regPhone = this.locale==='RU'? (/^(\+7\(?\d{3}\)?\d{3}-?\d{2}-?\d{2})$/) : (/^(\+)?([0-9]+)$/)

        if (!regPhone.test(String(this.form.phone))) {
          this.errors.phone = this.$t('business_account_data.correct_number')
        }
      }
      if (this.locale !== 'USA' && !this.form.offer_agreement_accepted) {
        this.errors.offer_agreement_accepted = this.$t('business_account_data.should_agree')
      }
      if (!this.form.legal_receiving_mail_index && this.form.legal_receiving_docs_method === 'by_email') {
        this.errors.legal_receiving_mail_index = this.$t('business_account_data.input_index')
      }
      if (!this.form.legal_receiving_mail_recipient && this.form.legal_receiving_docs_method === 'by_email') {
        this.errors.legal_receiving_mail_recipient = this.$t('business_account_data.input_receiver')
      }
      if (!this.form.legal_receiving_mail_address && this.form.legal_receiving_docs_method === 'by_email') {
        this.errors.legal_receiving_mail_address = this.$t('business_account_data.input_address')
      }
      if (!this.form.inn) {
        this.errors.inn = this.$t('business_account_data.input_inn')
      }
      if (!this.form.legal_name) {
        this.errors.legal_name = this.$t('business_account_data.input_legal')
      }
      if (!this.form.legal_address) {
        this.errors.legal_address = this.$t('business_account_data.input_legal_address')
      }
      if (!this.form.legal_contact_name) {
        this.errors.legal_contact_name = this.$t('business_account_data.input_name')
      }
      // if (!this.form.bank_name) {
      //   this.errors.bank_name = this.$t('business_account_data.input_bank')
      // }
      // if (!this.form.bic) {
      //   this.errors.bic = this.$t('business_account_data.input_bank_id')
      // }
      // if (!this.form.iban) {
      //   this.errors.iban = this.$t('business_account_data.input_account_number')
      // }
      // if (!this.form.swift_code) {
      //   this.errors.swift_code = this.$t('business_account_data.input_correspondent_account')
      // }
      if (this.locale==='RU' && !this.form.legal_receiving_physical_address) {
        this.errors.legal_receiving_physical_address = this.$t('business_account_data.input_mail_address')
      }
      // if (!this.form.bank_address) {
      //   this.errors.bank_address = this.$t('business_account_data.input_bank_address')
      // }
      if (this.locale==='RU' && this.form.legal_receiving_docs_method === 'not_receive') {
        this.errors.legal_receiving_docs_method = this.$t('business_account_data.input_receiving_way')
      }
      if (this.locale==='USA' &&!this.form.contract_signature_document) {
        this.errors.contract_signature_document = this.$t('business_account_data.input_based_position')
      }
      if (this.locale==='USA' && !this.form.contract_signature_position) {
        this.errors.contract_signature_position = this.$t('business_account_data.input_position')
      }
      if (this.locale==='USA' && !this.form.contract_signature_lastname) {
        this.errors.contract_signature_lastname = this.$t('business_account_data.input_last_name')
      }
      if (this.locale==='USA' && !this.form.contract_signature_firstname) {
        this.errors.contract_signature_firstname = this.$t('business_account_data.input_first_name')
      }
      if(this.locale === 'USA') {
        this.validateBank()
      }else {
        this.validateAdditionalBanksDefaultUSD()
      }
    },
    // validateBank () {
    //   if(
    //     !this.form.bank_name &&
    //     !this.form.bank_address &&
    //     !this.form.bic &&
    //     !this.form.swift_code &&
    //     !this.additional_banks.EUR.bank_name &&
    //     !this.additional_banks.EUR.bank_address &&
    //     !this.additional_banks.EUR.bic &&
    //     !this.additional_banks.EUR.swift_code
    //     ) {
    //       this.errors.bank_error = this.$t('business_account_data.bank_error')
    //       return false
    //     }
    //     if(
    //       this.form.bank_name ||
    //       this.form.bank_address ||
    //       this.form.bic ||
    //       this.form.swift_code 
    //     ) {
    //       this.validateAdditionalBanksDefaultUSD()
    //     }
    //     if(
    //       this.additional_banks.EUR.bank_name ||
    //       this.additional_banks.EUR.bank_address ||
    //       this.additional_banks.EUR.bic ||
    //       this.additional_banks.EUR.swift_code
    //     ) {
    //       this.validateAdditionalBanksEuro()
    //     }
    //     return true
    // },
    validateBank () {
        let usdValid = true;
        let eurValid = true;

        if (
            !this.form.bank_name &&
            !this.form.bank_address &&
            !this.form.bic &&
            !this.form.swift_code &&
            !this.additional_banks.EUR.bank_name &&
            !this.additional_banks.EUR.bank_address &&
            !this.additional_banks.EUR.bic &&
            !this.additional_banks.EUR.swift_code
        ) {
            this.errors.bank_error = this.$t('business_account_data.bank_error');
            return false;
        }

        if (
            this.form.bank_name ||
            this.form.bank_address ||
            this.form.bic ||
            this.form.swift_code
        ) {
            usdValid = this.validateAdditionalBanksDefaultUSD();
        }
        if (
            this.additional_banks.EUR.bank_name ||
            this.additional_banks.EUR.bank_address ||
            this.additional_banks.EUR.bic ||
            this.additional_banks.EUR.swift_code
        ) {
            eurValid = this.validateAdditionalBanksEuro();
        }

        if (!usdValid || !eurValid) {
            return false;
        }
        return true;
    },
    validateAdditionalBanksEuro () {
        if (!this.additional_banks.EUR.bank_name) {
          this.additional_banks_errors.EUR.bank_name = this.$t('business_account_data.input_bank')
          return false
        }
        if (!this.additional_banks.EUR.bank_address) {
          this.additional_banks_errors.EUR.bank_address = this.$t('business_account_data.input_bank_address')
          return false
        }
        if (!this.additional_banks.EUR.bic) {
          this.additional_banks_errors.EUR.bic = this.$t('business_account_data.input_bank_id')
          return false
        }
        if (!this.additional_banks.EUR.swift_code) {
          this.additional_banks_errors.EUR.swift_code = this.$t('business_account_data.input_correspondent_account')
          return false
        } 
        return true
    },
    validateAdditionalBanksDefaultUSD () {
      if (!this.form.bank_name) {
        this.errors.bank_name = this.$t('business_account_data.input_bank')
        return false
      }
      if (!this.form.bank_address) {
        this.errors.bank_address = this.$t('business_account_data.input_bank_address')
        return false
      }
      if (!this.form.bic) {
        this.errors.bic = this.$t('business_account_data.input_bank_id')
        return false
      }
      if (!this.form.swift_code) {
        this.errors.swift_code = this.$t('business_account_data.input_correspondent_account')
        return false
      }
      return true
    },

    copyAddress() {
      if (this.form.toggleAddress === true) {
        this.form.legal_receiving_physical_address = this.form.legal_address
        this.inputAddressDisabled = true
      } else {
        this.form.legal_receiving_physical_address = ''
        this.inputAddressDisabled = false
      }
    },
    closeDialog() {
      this.$store.dispatch('Dialog/closeDialog')
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
    },
    checkInn() {
      this.loading = true
      const data = {
        inn: this.form.inn,
      }
      this.$store.dispatch('Ad/checkInn', data)
        .then((data) => {
          this.isCorrectInn = true
          for (const key in data.data) {
            this.form[key] = data.data[key]
          }
          if (data.data.legal_address) this.isDisabledLegalAddress = true;
          this.loading = false
        })
        .catch((err) => {
          this.isCorrectInn = false
          this.errors.inn = err.errors.inn ? err.errors.inn[0] : err.errors
          this.loading = false
        })
    },
   isObjectEmpty(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'object') {
          if (!this.isObjectEmpty(obj[key])) {
            return false;
          }
        } else if (obj[key]) {
          return false;
        }
      }
      return true;
    },
    isObjectBankValid(obj) {
      if (!obj.EUR) {
        return false;
      }
      const { bank_address, bank_name, bic, iban, swift_code } = obj.EUR;
      return bank_address && bank_name && bic && iban && swift_code;
    },
    removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj)
            .map(([k, v]) => [k, v != null && v !== "" ? v : 0])
      );
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

  .dialog-add-account {

    .course-percent {
      display: flex;
      flex-wrap: wrap;
      gap:2rem;
      .dialog-add-account__form-item {
        width: calc(50% - 1rem);
        height: unset;
      }
    }

    &__form-type--radio {
      margin-bottom: 1.5rem;
    }
    .button {
      display: inline-block;
    }
    // .form-input.course-markup {
    //   input + .form-input__label {
    //     font-size: 1.2rem;
    //     line-height: 1.4rem;
    //     top: 0.8rem;
    //   }
    // }
    &__form-type {
      label:first-of-type {
        border-right: 1px solid #BFC2C6;
        &.error {
          border-color: #EB5757;
        }
      }
    }
  }
</style>
